@import '../base/mixins';
header{
    height: $header-height;
    
}
.hero-wrapper{
    height: calc(80dvh - $header-height) ;
    .right-col{
        justify-self: end;
        align-self: center;
    }
    @include max-mobile{
        .right-col{
            display: none;
        }
    }
    
}

section{
    .section-heading{
        align-self: center;
    }
}
.layout-copy{
    align-self: center;
}
.right-align{
    justify-self: end;
    align-self: center;
}
.premium{
    .premium-wrapper{
        max-width: 500px;
    }
}
.featured-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    .img-cont{
        height: 50px;
        svg{
            scale: 2;
            margin-inline: auto;
        }
        button{
            display: block;
            padding-inline: 4rem;
        }
    }
}
footer{
    textarea{
        min-height: 5rem;
    }
    button{
        width: 100%;
        border-radius: 0 !important;
    }
    .social-links{
        svg{
            font-size: 2rem;
            color: $clr-primary-800;
        }
    }
}

.premium{
    .hero-wrapper{
        background-image: url(../images/hero-background.svg);
        background-position: center;
    }
    .hero{
        height: calc(80dvh - $header-height) ;
    }

}
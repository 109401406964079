//colors

.bg-primary-200{
    background-color: $clr-primary-200;
}
.bg-primary-400{
    background-color: $clr-primary-400;
}
.bg-primary-800{
    background-color: $clr-primary-800;
}
.bg-accent-400{
    background-color: $clr-accent-400;
}
.bg-accent-800{
    background-color: $clr-accent-800;
}
.bg-dark-400{
    background-color: $clr-dark-400;
}

//Text colors

.clr-primary-200{
    color: $clr-primary-200;
}
.clr-primary-400{
    color: $clr-primary-400;
}
.clr-primary-800{
    color: $clr-primary-800;
}
.clr-accent-400{
    color: $clr-accent-400;
}
.clr-accent-800{
    color: $clr-accent-800;
}
.clr-dark-400{
    color: $clr-dark-400;
} 
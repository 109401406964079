@import '../mixins/flex';

//typogrophy

.hero-heading{
    font-size: clamp($fs-600, 5vw, $fs-700);
    font-weight: $fw-bold;
    font-size: $ff-heading;
}
.primary-heading{
    font-size: $fs-600;
    font-size: $ff-heading;

}
.section-heading{
    font-size: clamp($fs-300, 2vw, $fs-600);
    font-weight: $fw-bold;
    // font-size: $ff-heading;
    // font-size: $fs-600;
}
.secondary-heading{
    font-size: $ff-heading;
    font-size: $fs-500;
    margin-bottom: $secondary-heading-padding;
}
.card-heading{
    font-size: $fs-500;
    font-size: $fw-bold;
}
.primary-body{
    font-size: $fs-400;
    font-weight: $fw-regular;
}
.secondary-body{
    font-size: $fs-300;
}
.sub-body{
    font-size: $fs-200;
    font-weight: $fw-light;
}


.fs-700{
    font-size: $fs-700;
}
.fs-600{
    font-size: $fs-600;
}
.fs-500{
    font-size: $fs-500;
}
.fs-400{
    font-size: $fs-400;
}
.fs-300{
    font-size: $fs-300;
}
.fs-200{
    font-size: $fs-200;
}
.fw-bold{
    font-weight: $fw-bold;
}
.fw-medium{
    font-weight: $fw-medium;
}
.fw-regular{
    font-weight: $fw-regular;
}
.fw-light{
    font-weight: $fw-light;
}
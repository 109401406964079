//Utility classes
//hide
[datatype-hide='hide']{
    display: none;
}
//position
.relative{
    position: relative;
}
.position-center{
    margin:auto;
    inset: 0;
    position: absolute;
}
.max-width{
    width: 100%;
}
//text

.text-center{
    text-align: center;
}
.text-wrap-pretty{
    text-wrap: pretty;
}
.text-wrap-balance{
    text-wrap: balance;
}
//icons

.social-icon{
    font-size: $size-5;
}

//divider
.divider{
    margin-block: $divider-margin-block;
    height: $divider-height;
    width: 100%;
    background-color: $divider-color;
}
.pointer{
    cursor: pointer;
}
.loading{
    height: 100%;
    svg{
        margin-inline: auto;
        width: 5rem;
        color: $clr-accent-400;
    }
}
.block{
    display: block;
}
.inline-block{
    display: inline-block;
}


.popup{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateY(100%);
    border-radius: 5px;
    &[datatype-popup="active"]{
        animation: popup 2000ms;
        animation-timing-function: ease-in-out;
    }
}
@keyframes popup {
    0%{
        transform: translateY(100%); 
    }
    20%{
        transform: translateY(-50%);
    }
    70%{

        transform: translateY(-50%);
    }
    0%{
        transform: translateY(100%);
    }
}
dialog{
    // position: relative;
}
.primary-popup{
    width: 500px;
    border: none;
    box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    .primary-popup-wrapper {
        position: fixed;
        // margin-top: -5rem;
        transform: translateY(-50%);
        background-color: $clr-primary-200;
        border-radius: 10px;
        max-width: 500px;
    }
}


.section{
    padding-block: $section-padding;
}
.mx-auto{
    margin-inline: auto;
}


@each $size-name, $size-value in $sizes{
    //margin
    .mg {
        &-#{$size-name}{
            margin: $size-value
        }
        &-inline-#{$size-name}{
            margin-inline: $size-value;
        }
        &-inline-start-#{$size-name}{
            margin-inline-start: $size-value;
        }
        &-inline-end-#{$size-name}{
            margin-inline-end: $size-value;
        }
        &-block-#{$size-name}{
            margin-block: $size-value;
        }
        &-block-start-#{$size-name}{
            margin-block-start: $size-value;
        }
        &-block-end-#{$size-name}{
            margin-block-end: $size-value;
        }
    }
    .pg{
        &-#{$size-name}{
            padding: $size-value
        }
        &-inline-#{$size-name}{
            padding-inline: $size-value;
        }
        &-inline-start-#{$size-name}{
            padding-inline-start: $size-value;
        }
        &-inline-end-#{$size-name}{
            padding-inline-end: $size-value;
        }
        &-block-#{$size-name}{
            padding-block: $size-value;
        }
        &-block-start-#{$size-name}{
            padding-block-start: $size-value;
        }
        &-block-end-#{$size-name}{
            padding-block-end: $size-value;
        }
    }
    .gap{
        &-#{$size-name}{
            gap: $size-value;
        }
    }
}


.login-wrapper{
    background: linear-gradient(301deg, #F5F4F1 0%, rgba(29, 128, 186, 0.14) 89.59%);
    height: 100dvh;
    .login{
        box-shadow: 4px 4px 15.3px #00000040;
        li{
            input{
                width: 100%;
                border-color: hsl(from $clr-gray-400 h s l / .5);
                color: $clr-dark-400;
            }
        }
        button{
            width: 100%;
        }
        span{
            cursor: pointer;
            color: $clr-accent-400;
        }
    }
}
.grid{
    display: grid;
}
.even-columns{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}
.responsive-even-columns{
    --_grid-max-width: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--_grid-max-width), 1fr));
}
.grid-area{
    display: grid;
}
.grid-center{
    display: grid;
    justify-items: center;
}
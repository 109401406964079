.main-wrapper{
    width: 100%;
    // background: linear-gradient(301deg, #F5F4F1 0%, rgba(212, 234, 247, 0.14) 89.59%);
    background: linear-gradient(301deg, #F5F4F1 0%, rgba(29, 128, 186, 0.14) 89.59%);
    .search-bar{
        max-height: 60px;
        box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
        width: 100%;
    }
    .buttons-wrapper{
        max-width: 210px;
        margin-inline: auto;
        box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
        button{
            cursor: pointer;
            svg{
                color: $clr-accent-400;
                transition: color 150ms;
                &:hover{
                    color: $clr-accent-800;
                }
            }
        }
    }
    .main{
        transition: max-width 250ms;
    }
}

.layouts-wrapper > div{
    margin-inline: auto;
    border-radius: 10px;
    box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
    transition: transform 200ms;
    cursor: pointer;
    &:hover{
        transform: scale(1.01);
    }
}
.filler-layout-wrapper{
    position: relative;
    .dark-overlay{
        position: absolute;
        background-color: #000000ec;
        backdrop-filter: blur(3px);
        width: 100%;
        height: 100%;
        svg, h2{
            color: white;
        }
        svg{
            font-size: 2rem;
        }
    }
}
.layout-wrapper{
    margin-inline: auto;
}
.add-layout-wrapper{
    textarea{
        background-color: $clr-primary-400;
        color: $clr-dark-400;
        overflow-x: auto;
        text-wrap: nowrap;
    }
}

//cover

.cover-layout-wrapper{
    color: white;
    .cover{
        background: #000 url(../images/cover-background.png) no-repeat center;
        min-width: 450px;
        min-height: 350px;
        height: 550px;
        // aspect-ratio: 2/1;
        button[data-type='secondary']{
            color: white    ;
            background-color: black !important;
            border: 1px solid rgba(255, 255, 255, 0.42);
        }
    }
}

//About Us
.about-us{
    background-color: $clr-primary-400;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // padding: 3rem 4rem;
    // gap: 3rem;
    // height: 500px;
    .left{
        border-radius: 10px;
        background: #000 url(../images/cover-background.png) no-repeat center;
    }
    .eyebrow{
        margin-bottom: .5rem;
    }
    .heading{
        margin-bottom: 1.8rem;
    }
    p{
        color: $clr-gray-400;
    }
}


//Our services
.our-services{
    background-color: $clr-primary-400;
    img{
        content: url(../images/logo.svg);
    }
    p{
        color: $clr-gray-400;
    }

}

#our-services-2{
    .img-wrapper{
        background-color: $clr-accent-800;
    }
}
#our-services-3{
    li{
        text-align: left;
        display: grid;
        grid-template-columns: 3rem 1fr;
        gap: .5rem;
    }
    .img-wrapper{
        background-color: $clr-accent-800;
    }
}


//featured
.featured{
    background-color: $clr-primary-400;
    img{
        content: url(../images/logo.svg);
    }
    p{
        color: $clr-gray-400;
    }
}

#featured-2{
    li{
        .image-wrapper{
            width: 3rem;
            height: 3rem;
            background-color: $clr-accent-800;
            border-radius: 50vw;
            padding: .5rem;
        }
    }
}
#featured-4{
    .left-col{
        background: #000 url(../images/cover-background.png) no-repeat center;
    }
}


//pricing
.pricing{
    background-color: $clr-primary-400;
    p{
        color: $clr-gray-400;
    }
    img{
        content: url(../images/logo.svg);
    }
}
#pricing-1{
    .price{
        color: $clr-accent-400;
    }
    li:nth-child(2n){
        background-color: $clr-primary-800;
    }

}
#pricing-2{
    li{
        background-color: $clr-primary-800;
        
    }
}


//Testimonials

.testimonial{
    background-color: $clr-primary-400;
    h1{
        color: $clr-accent-400;
    }
    p{
        color: $clr-gray-400;
    }
    .client-name{
        color: $clr-dark-400;
        font-weight: bold;
    }
    img{
        content: url(../images/logo.svg);
    }
    .image-wrapper{
        background-color: $clr-dark-400;

    }
}
#testimonial-3{
    li{
        .testimonial-wrapper{
                padding: 1.5rem;
                background-color: $clr-primary-800;

        }
        &:nth-child(2){
            .testimonial-wrapper{
                background-color: $clr-accent-400;
                color: $clr-primary-800;
                h1{
                    color: $clr-primary-800 ;
                }
                p{
                    color: $clr-primary-800 ;
                }
            }
        }
    }
}


@import '../base/variables';
@import '../base/tokens';
.container{
    --_screen-max-width: #{$screen-max-width};
    width: min(var(--_screen-max-width), 100% - 1rem *  2);
    margin-inline: auto;
}
// .content-grid{
//     --padding-inline: 2rem;
//     --content-max-width: $screen-max-width;
//     --breakout-max-width: 85rem;


//     display: grid;
//     grid-template-columns: 
//     [full-width-start]
//     minmax( var(--padding-inline), 1fr)
//         [breakout-start]
//     0px
//         [content-start]
//     min(var(--_page-width), 100% - var(--padding-inline) *  2)
//         [content-end]
//     0px
//         [breakout-end]
//     minmax( var(--padding-inline), 1fr)
//     [full-width-end];
// }

// .content-grid > *{
//     grid-column: content;
// }
// .content-grid > .breakout{
//     grid-column: breakout;
// }
// .content-grid > .full-width{
//     grid-column: full-width;
// }
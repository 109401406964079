.top{
    border-bottom: 1px solid hsla(45, 17%, 95%, 1);
    .top_button{
        transition: background-color 200ms;
        padding: 1rem;
    }
    .top_button:hover{
        background-color: $clr-primary-800;
    }
    svg{
        width: 1.2rem;
        height: 1.2rem;
    }
    .select-code-wrapper{
        transition: background-color 200ms;
    }
    .select-code-wrapper:hover{
        background-color: $clr-primary-800;
        transition: background-color 200ms;
    }
    .select-code{
        border: none;
        option{
            color: $clr-primary-400;
        }
    }
}

.code-container-section{ 
    transition: max-width 550px;
    .component{
        box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
    }
    .code-container-wrapper{
        .code-container{
            background-color: $clr-primary-800;
            overflow-x: hidden;
        }
        code{
            // max-inline-size: 70vw;
        }
    }
}
textarea{
    display: block;
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-size: $fs-400;
    min-height: 500px;
}

.profile-wrapper{
    margin-top: auto;
}
.sidebar-wrapper{
    height: 100dvh;
    overflow-x: hidden;
    min-width: 196px;
    &[datatype='collapse']{
        min-width: 110px;
        .logo{
            display: none;
        }
        .logout-wrapper{
            p{
                display: none;
            }
        }
        .collapse-icon{
            transform: rotate(0deg);
            margin-inline: auto;
        }
        .sidebar{
            min-width: 50px;
        }
    }
    .sidebar{
        height: 100%;
        position: fixed;
    }
    .collapse-icon{
        transform: rotate(180deg);
        margin-left: auto;
        color: $clr-dark-400;
    }
    .sidebar_buttons_wrapper{
        cursor: pointer;
        .sidebar_buttons{
            &:hover{
                background-color: #F5F4F1;
            }
            .sidebar_icon{
                svg{
                    min-width: $size-4;
                    width: $size-4;
                }
            }
            .dropdown_button{
                margin-left: auto;
                &[datatype='deactive']{
                    transform: rotate(-90deg);
                }
                svg{
                    min-width: $size-2;
                }
            }
        }
        .sub_sidebar_buttons{
            transition: transform 150ms;
            transform-origin: top;
            background-color: #F5F4F1;
            display: flex;
            flex-direction: column;
            &[datatype='deactive']{
                height: 0;  
                transform: scaleY(0);
                .sub_sidebar_button{
                    font-size: 0px;
                }
            }
            &[datatype='active']{
                transform: scaleY(1);
            }
            .sub_sidebar_button{
                width: 100%;
                text-align: start;
                cursor: pointer;
                &:hover{
                    background-color: $clr-accent-400;
                    color: #F5F4F1;
                }
            }
        }
    }
    .logout-wrapper{
        margin-top: auto;
    }
}

.sub_sidebar_button, button{
    background: transparent;
    outline: transparent;
    border: transparent;
}
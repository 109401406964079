.buttons-component{
    select{
        // background-color: $clr-primary-800;
        color: $clr-accent-400;
        border-color: $clr-accent-400;
        option{
            background-color: $clr-primary-400;
            color: $clr-dark-400;
        }
    }
}
.color-picker-button{
    position:sticky;
    z-index: 99;
    padding-inline: .5rem;
    // font-size: 2rem;
}
.color-input{
    background-color: white;
    color: black;
}
.picker{
    input{
        color: $clr-dark-400;
    }
}
button[datatype-buttonactive='true']{
    border: 2px solid $clr-accent-400;
}
.buttons-container{
    button{
        padding: .5rem 1rem;

    }
}
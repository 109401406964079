@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Merriweather+Sans:wght@300;400;700&display=swap');
$clr-primary-200: hsl(0, 0%, 100%);
$clr-primary-400: hsl(45, 100%, 99%);
$clr-primary-800: hsl(45, 15%, 95%);
$clr-accent-400: hsl(196, 100%, 27%);
$clr-accent-500: hsl(200, 80%, 69%);
$clr-accent-800: hsl(202, 69%, 90%);
$clr-dark-400: hsl(0, 2%, 11%);
$clr-gray-400: hsl(0, 0%, 45%);

$fs-200: .875rem;
$fs-300: 1rem;
$fs-400: 1.25rem;
$fs-500: 1.5rem;
$fs-600: 2.25rem;
$fs-700: 3rem;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

$ff-primary:'Merriweather Sans', sans-serif;
$ff-heading:'Fira Sans', sans-serif;


$line-height: 1.4;
$letter-spacing: 0;

$size-0: .3125rem;
$size-1: .625rem;
$size-2: .9375rem;
$size-3: 1.25rem;
$size-4: 1.5625rem;
$size-5: 1.875rem;
$size-6: 2.1875rem;
$size-7: 2.5rem;
$size-8: 2.8125rem;
$size-9: 3.125rem;
$size-10: 3.4375rem;
$size-11: 4.375rem;
$size-12: 6.25rem;
$size-13: 7.5rem;
$size-14: 9.375rem;
$size-15: 10.625rem;

$breakpoint-small: 50em;
$breakpoint-medium: 65rem;
$breakpoint-large: 90rem;

$screen-max-width: 70rem;


.button[datatype-button='primary']{
    background-color: $clr-accent-400;
    color: $clr-primary-400;
    padding: .7rem 1rem ;
    transition: background-color 250ms;
    border-radius: 50vw;
    font-size: clamp(.25rem, 2.5vw,1rem) ;
    &:hover{
        background-color: $clr-accent-800;
        color: $clr-dark-400;
    }
}
.button[datatype-button='secondary']{
    border-radius: 50vw;
    background-color: $clr-primary-400;
    color: $clr-accent-400;
    padding: .7rem 1rem ;
    transition: background-color 250ms;
    font-size: clamp(.25rem, 2.5vw,1rem) ;
    // width: 100%;
    &:hover{
        background-color: $clr-accent-800;
        color: $clr-dark-400;
    }
}
.button-img{
    display: flex;
    align-items: center;
    gap: .5rem;
    display: inline-flex;
}
.section-img{
    justify-self: center;
}
.button{
    cursor: pointer;
}


@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Merriweather+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Merriweather+Sans:wght@300;400;700&display=swap");
li {
  list-style-type: none;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Merriweather Sans", sans-serif;
}

body {
  min-height: 100dvh;
  letter-spacing: 0;
  font-family: "Merriweather Sans", sans-serif;
  overflow-x: hidden;
}

svg, img, video {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

input, select, textarea {
  background-color: transparent;
  color: hsl(45, 100%, 99%);
  padding-inline: 0.625rem;
  padding-block: 0.625rem;
  border: 1.5px solid hsl(from hsl(45, 100%, 99%) h s l/0.5);
  outline: transparent;
  border-radius: 2px;
  resize: none;
}

option {
  background-color: hsl(0, 2%, 11%);
}

.sidebar-wrapper {
  height: 100dvh;
  overflow-x: hidden;
  min-width: 196px;
}
.sidebar-wrapper[datatype=collapse] {
  min-width: 110px;
}
.sidebar-wrapper[datatype=collapse] .logo {
  display: none;
}
.sidebar-wrapper[datatype=collapse] .logout-wrapper p {
  display: none;
}
.sidebar-wrapper[datatype=collapse] .collapse-icon {
  transform: rotate(0deg);
  margin-inline: auto;
}
.sidebar-wrapper[datatype=collapse] .sidebar {
  min-width: 50px;
}
.sidebar-wrapper .sidebar {
  height: 100%;
  position: fixed;
}
.sidebar-wrapper .collapse-icon {
  transform: rotate(180deg);
  margin-left: auto;
  color: hsl(0, 2%, 11%);
}
.sidebar-wrapper .sidebar_buttons_wrapper {
  cursor: pointer;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sidebar_buttons:hover {
  background-color: #F5F4F1;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sidebar_buttons .sidebar_icon svg {
  min-width: 1.5625rem;
  width: 1.5625rem;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sidebar_buttons .dropdown_button {
  margin-left: auto;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sidebar_buttons .dropdown_button[datatype=deactive] {
  transform: rotate(-90deg);
}
.sidebar-wrapper .sidebar_buttons_wrapper .sidebar_buttons .dropdown_button svg {
  min-width: 0.9375rem;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons {
  transition: transform 150ms;
  transform-origin: top;
  background-color: #F5F4F1;
  display: flex;
  flex-direction: column;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons[datatype=deactive] {
  height: 0;
  transform: scaleY(0);
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons[datatype=deactive] .sub_sidebar_button {
  font-size: 0px;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons[datatype=active] {
  transform: scaleY(1);
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons .sub_sidebar_button {
  width: 100%;
  text-align: start;
  cursor: pointer;
}
.sidebar-wrapper .sidebar_buttons_wrapper .sub_sidebar_buttons .sub_sidebar_button:hover {
  background-color: hsl(196, 100%, 27%);
  color: #F5F4F1;
}
.sidebar-wrapper .logout-wrapper {
  margin-top: auto;
}

.sub_sidebar_button, button {
  background: transparent;
  outline: transparent;
  border: transparent;
}

.main-wrapper {
  width: 100%;
  background: linear-gradient(301deg, #F5F4F1 0%, rgba(29, 128, 186, 0.14) 89.59%);
}
.main-wrapper .search-bar {
  max-height: 60px;
  box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
}
.main-wrapper .buttons-wrapper {
  max-width: 210px;
  margin-inline: auto;
  box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
}
.main-wrapper .buttons-wrapper button {
  cursor: pointer;
}
.main-wrapper .buttons-wrapper button svg {
  color: hsl(196, 100%, 27%);
  transition: color 150ms;
}
.main-wrapper .buttons-wrapper button svg:hover {
  color: hsl(202, 69%, 90%);
}
.main-wrapper .main {
  transition: max-width 250ms;
}

.layouts-wrapper > div {
  margin-inline: auto;
  border-radius: 10px;
  box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
  transition: transform 200ms;
  cursor: pointer;
}
.layouts-wrapper > div:hover {
  transform: scale(1.01);
}

.filler-layout-wrapper {
  position: relative;
}
.filler-layout-wrapper .dark-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9254901961);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
}
.filler-layout-wrapper .dark-overlay svg, .filler-layout-wrapper .dark-overlay h2 {
  color: white;
}
.filler-layout-wrapper .dark-overlay svg {
  font-size: 2rem;
}

.layout-wrapper {
  margin-inline: auto;
}

.add-layout-wrapper textarea {
  background-color: hsl(45, 100%, 99%);
  color: hsl(0, 2%, 11%);
  overflow-x: auto;
  text-wrap: nowrap;
}

.cover-layout-wrapper {
  color: white;
}
.cover-layout-wrapper .cover {
  background: #000 url(../images/cover-background.png) no-repeat center;
  min-width: 450px;
  min-height: 350px;
  height: 550px;
}
.cover-layout-wrapper .cover button[data-type=secondary] {
  color: white;
  background-color: black !important;
  border: 1px solid rgba(255, 255, 255, 0.42);
}

.about-us {
  background-color: hsl(45, 100%, 99%);
}
.about-us .left {
  border-radius: 10px;
  background: #000 url(../images/cover-background.png) no-repeat center;
}
.about-us .eyebrow {
  margin-bottom: 0.5rem;
}
.about-us .heading {
  margin-bottom: 1.8rem;
}
.about-us p {
  color: hsl(0, 0%, 45%);
}

.our-services {
  background-color: hsl(45, 100%, 99%);
}
.our-services img {
  content: url(../images/logo.svg);
}
.our-services p {
  color: hsl(0, 0%, 45%);
}

#our-services-2 .img-wrapper {
  background-color: hsl(202, 69%, 90%);
}

#our-services-3 li {
  text-align: left;
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 0.5rem;
}
#our-services-3 .img-wrapper {
  background-color: hsl(202, 69%, 90%);
}

.featured {
  background-color: hsl(45, 100%, 99%);
}
.featured img {
  content: url(../images/logo.svg);
}
.featured p {
  color: hsl(0, 0%, 45%);
}

#featured-2 li .image-wrapper {
  width: 3rem;
  height: 3rem;
  background-color: hsl(202, 69%, 90%);
  border-radius: 50vw;
  padding: 0.5rem;
}

#featured-4 .left-col {
  background: #000 url(../images/cover-background.png) no-repeat center;
}

.pricing {
  background-color: hsl(45, 100%, 99%);
}
.pricing p {
  color: hsl(0, 0%, 45%);
}
.pricing img {
  content: url(../images/logo.svg);
}

#pricing-1 .price {
  color: hsl(196, 100%, 27%);
}
#pricing-1 li:nth-child(2n) {
  background-color: hsl(45, 15%, 95%);
}

#pricing-2 li {
  background-color: hsl(45, 15%, 95%);
}

.testimonial {
  background-color: hsl(45, 100%, 99%);
}
.testimonial h1 {
  color: hsl(196, 100%, 27%);
}
.testimonial p {
  color: hsl(0, 0%, 45%);
}
.testimonial .client-name {
  color: hsl(0, 2%, 11%);
  font-weight: bold;
}
.testimonial img {
  content: url(../images/logo.svg);
}
.testimonial .image-wrapper {
  background-color: hsl(0, 2%, 11%);
}

#testimonial-3 li .testimonial-wrapper {
  padding: 1.5rem;
  background-color: hsl(45, 15%, 95%);
}
#testimonial-3 li:nth-child(2) .testimonial-wrapper {
  background-color: hsl(196, 100%, 27%);
  color: hsl(45, 15%, 95%);
}
#testimonial-3 li:nth-child(2) .testimonial-wrapper h1 {
  color: hsl(45, 15%, 95%);
}
#testimonial-3 li:nth-child(2) .testimonial-wrapper p {
  color: hsl(45, 15%, 95%);
}

.top {
  border-bottom: 1px solid hsl(45, 17%, 95%);
}
.top .top_button {
  transition: background-color 200ms;
  padding: 1rem;
}
.top .top_button:hover {
  background-color: hsl(45, 15%, 95%);
}
.top svg {
  width: 1.2rem;
  height: 1.2rem;
}
.top .select-code-wrapper {
  transition: background-color 200ms;
}
.top .select-code-wrapper:hover {
  background-color: hsl(45, 15%, 95%);
  transition: background-color 200ms;
}
.top .select-code {
  border: none;
}
.top .select-code option {
  color: hsl(45, 100%, 99%);
}

.code-container-section {
  transition: max-width 550px;
}
.code-container-section .component {
  box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
}
.code-container-section .code-container-wrapper .code-container {
  background-color: hsl(45, 15%, 95%);
  overflow-x: hidden;
}
textarea {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 1.5;
  font-size: 1.25rem;
  min-height: 500px;
}

.profile-wrapper {
  margin-top: auto;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateY(100%);
  border-radius: 5px;
}
.popup[datatype-popup=active] {
  animation: popup 2000ms;
  animation-timing-function: ease-in-out;
}

@keyframes popup {
  0% {
    transform: translateY(100%);
  }
  20% {
    transform: translateY(-50%);
  }
  70% {
    transform: translateY(-50%);
  }
  0% {
    transform: translateY(100%);
  }
}
.primary-popup {
  width: 500px;
  border: none;
  box-shadow: 3px 3px 13.6px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}
.primary-popup .primary-popup-wrapper {
  position: fixed;
  transform: translateY(-50%);
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  max-width: 500px;
}

.login-wrapper {
  background: linear-gradient(301deg, #F5F4F1 0%, rgba(29, 128, 186, 0.14) 89.59%);
  height: 100dvh;
}
.login-wrapper .login {
  box-shadow: 4px 4px 15.3px rgba(0, 0, 0, 0.2509803922);
}
.login-wrapper .login li input {
  width: 100%;
  border-color: hsl(from hsl(0, 0%, 45%) h s l/0.5);
  color: hsl(0, 2%, 11%);
}
.login-wrapper .login button {
  width: 100%;
}
.login-wrapper .login span {
  cursor: pointer;
  color: hsl(196, 100%, 27%);
}

.button[datatype-button=primary] {
  background-color: hsl(196, 100%, 27%);
  color: hsl(45, 100%, 99%);
  padding: 0.7rem 1rem;
  transition: background-color 250ms;
  border-radius: 50vw;
  font-size: clamp(0.25rem, 2.5vw, 1rem);
}
.button[datatype-button=primary]:hover {
  background-color: hsl(202, 69%, 90%);
  color: hsl(0, 2%, 11%);
}

.button[datatype-button=secondary] {
  border-radius: 50vw;
  background-color: hsl(45, 100%, 99%);
  color: hsl(196, 100%, 27%);
  padding: 0.7rem 1rem;
  transition: background-color 250ms;
  font-size: clamp(0.25rem, 2.5vw, 1rem);
}
.button[datatype-button=secondary]:hover {
  background-color: hsl(202, 69%, 90%);
  color: hsl(0, 2%, 11%);
}

.button-img {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  display: inline-flex;
}

.section-img {
  justify-self: center;
}

.button {
  cursor: pointer;
}

.buttons-component select {
  color: hsl(196, 100%, 27%);
  border-color: hsl(196, 100%, 27%);
}
.buttons-component select option {
  background-color: hsl(45, 100%, 99%);
  color: hsl(0, 2%, 11%);
}

.color-picker-button {
  position: sticky;
  z-index: 99;
  padding-inline: 0.5rem;
}

.color-input {
  background-color: white;
  color: black;
}

.picker input {
  color: hsl(0, 2%, 11%);
}

button[datatype-buttonactive=true] {
  border: 2px solid hsl(196, 100%, 27%);
}

.buttons-container button {
  padding: 0.5rem 1rem;
}

header {
  height: 6.25rem;
}

.hero-wrapper {
  height: calc(80dvh - 6.25rem);
}
.hero-wrapper .right-col {
  justify-self: end;
  align-self: center;
}
@media (max-width: 50em) {
  .hero-wrapper .right-col {
    display: none;
  }
}

section .section-heading {
  align-self: center;
}

.layout-copy {
  align-self: center;
}

.right-align {
  justify-self: end;
  align-self: center;
}

.premium .premium-wrapper {
  max-width: 500px;
}

.featured-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}
.featured-wrapper .img-cont {
  height: 50px;
}
.featured-wrapper .img-cont svg {
  scale: 2;
  margin-inline: auto;
}
.featured-wrapper .img-cont button {
  display: block;
  padding-inline: 4rem;
}

footer textarea {
  min-height: 5rem;
}
footer button {
  width: 100%;
  border-radius: 0 !important;
}
footer .social-links svg {
  font-size: 2rem;
  color: hsl(45, 15%, 95%);
}

.premium .hero-wrapper {
  background-image: url(../images/hero-background.svg);
  background-position: center;
}
.premium .hero {
  height: calc(80dvh - 6.25rem);
}

.flex, .flex-wrap, .flex-jc-c, .flex-jc-e, .flex-jc-sb, .flex-al-c, .flex-center {
  display: flex;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-al-c {
  align-items: center;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-e {
  justify-content: end;
}
.flex-jc-c {
  justify-content: center;
}
.flex-js-e {
  margin-left: auto;
}
.flex-js-s {
  margin-right: auto;
}
.flex-wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.grid {
  display: grid;
}

.even-columns {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.responsive-even-columns {
  --_grid-max-width: 250px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--_grid-max-width), 1fr));
}

.grid-area {
  display: grid;
}

.grid-center {
  display: grid;
  justify-items: center;
}

.border-radius-0 {
  border-radius: 0.3125rem;
}

.border-radius-1 {
  border-radius: 0.625rem;
}

.border-radius-2 {
  border-radius: 0.9375rem;
}

.border-radius-3 {
  border-radius: 1.25rem;
}

.border-radius-4 {
  border-radius: 1.5625rem;
}

.border-radius-5 {
  border-radius: 1.875rem;
}

.border-radius-6 {
  border-radius: 2.1875rem;
}

.border-radius-7 {
  border-radius: 2.5rem;
}

.border-radius-8 {
  border-radius: 2.8125rem;
}

.border-radius-9 {
  border-radius: 3.125rem;
}

.border-radius-10 {
  border-radius: 3.4375rem;
}

.border-radius-11 {
  border-radius: 4.375rem;
}

.border-radius-12 {
  border-radius: 6.25rem;
}

.border-radius-13 {
  border-radius: 7.5rem;
}

.border-radius-14 {
  border-radius: 9.375rem;
}

.border-radius-15 {
  border-radius: 10.625rem;
}

.border-circle {
  border-radius: 50vw;
}

.bg-primary-200 {
  background-color: hsl(0, 0%, 100%);
}

.bg-primary-400 {
  background-color: hsl(45, 100%, 99%);
}

.bg-primary-800 {
  background-color: hsl(45, 15%, 95%);
}

.bg-accent-400 {
  background-color: hsl(196, 100%, 27%);
}

.bg-accent-800 {
  background-color: hsl(202, 69%, 90%);
}

.bg-dark-400 {
  background-color: hsl(0, 2%, 11%);
}

.clr-primary-200 {
  color: hsl(0, 0%, 100%);
}

.clr-primary-400 {
  color: hsl(45, 100%, 99%);
}

.clr-primary-800 {
  color: hsl(45, 15%, 95%);
}

.clr-accent-400 {
  color: hsl(196, 100%, 27%);
}

.clr-accent-800 {
  color: hsl(202, 69%, 90%);
}

.clr-dark-400 {
  color: hsl(0, 2%, 11%);
}

.container {
  --_screen-max-width: 70rem;
  width: min(var(--_screen-max-width), 100% - 2rem);
  margin-inline: auto;
}

.flex-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.flow-0 > :where(:not(:first-child)) {
  margin-top: 0.3125rem;
}

.flow-1 > :where(:not(:first-child)) {
  margin-top: 0.625rem;
}

.flow-2 > :where(:not(:first-child)) {
  margin-top: 0.9375rem;
}

.flow-3 > :where(:not(:first-child)) {
  margin-top: 1.25rem;
}

.flow-4 > :where(:not(:first-child)) {
  margin-top: 1.5625rem;
}

.flow-5 > :where(:not(:first-child)) {
  margin-top: 1.875rem;
}

.flow-6 > :where(:not(:first-child)) {
  margin-top: 2.1875rem;
}

.flow-7 > :where(:not(:first-child)) {
  margin-top: 2.5rem;
}

.flow-8 > :where(:not(:first-child)) {
  margin-top: 2.8125rem;
}

.flow-9 > :where(:not(:first-child)) {
  margin-top: 3.125rem;
}

.flow-10 > :where(:not(:first-child)) {
  margin-top: 3.4375rem;
}

.flow-11 > :where(:not(:first-child)) {
  margin-top: 4.375rem;
}

.flow-12 > :where(:not(:first-child)) {
  margin-top: 6.25rem;
}

.flow-13 > :where(:not(:first-child)) {
  margin-top: 7.5rem;
}

.flow-14 > :where(:not(:first-child)) {
  margin-top: 9.375rem;
}

.flow-15 > :where(:not(:first-child)) {
  margin-top: 10.625rem;
}

.hero-heading {
  font-size: clamp(2.25rem, 5vw, 3rem);
  font-weight: 700;
  font-size: "Fira Sans", sans-serif;
}

.primary-heading {
  font-size: 2.25rem;
  font-size: "Fira Sans", sans-serif;
}

.section-heading {
  font-size: clamp(1rem, 2vw, 2.25rem);
  font-weight: 700;
}

.secondary-heading {
  font-size: "Fira Sans", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 7.5rem;
}

.card-heading {
  font-size: 1.5rem;
  font-size: 700;
}

.primary-body {
  font-size: 1.25rem;
  font-weight: 400;
}

.secondary-body {
  font-size: 1rem;
}

.sub-body {
  font-size: 0.875rem;
  font-weight: 300;
}

.fs-700 {
  font-size: 3rem;
}

.fs-600 {
  font-size: 2.25rem;
}

.fs-500 {
  font-size: 1.5rem;
}

.fs-400 {
  font-size: 1.25rem;
}

.fs-300 {
  font-size: 1rem;
}

.fs-200 {
  font-size: 0.875rem;
}

.fw-bold {
  font-weight: 700;
}

.fw-medium {
  font-weight: 500;
}

.fw-regular {
  font-weight: 400;
}

.fw-light {
  font-weight: 300;
}

[datatype-hide=hide] {
  display: none;
}

.relative {
  position: relative;
}

.position-center {
  margin: auto;
  inset: 0;
  position: absolute;
}

.max-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-wrap-pretty {
  text-wrap: pretty;
}

.text-wrap-balance {
  text-wrap: balance;
}

.social-icon {
  font-size: 1.875rem;
}

.divider {
  margin-block: 0px;
  height: 1px;
  width: 100%;
  background-color: hsla(0, 0%, 0%, 0.315);
}

.pointer {
  cursor: pointer;
}

.loading {
  height: 100%;
}
.loading svg {
  margin-inline: auto;
  width: 5rem;
  color: hsl(196, 100%, 27%);
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.section {
  padding-block: 7.5rem;
}

.mx-auto {
  margin-inline: auto;
}

.mg-0 {
  margin: 0.3125rem;
}
.mg-inline-0 {
  margin-inline: 0.3125rem;
}
.mg-inline-start-0 {
  margin-inline-start: 0.3125rem;
}
.mg-inline-end-0 {
  margin-inline-end: 0.3125rem;
}
.mg-block-0 {
  margin-block: 0.3125rem;
}
.mg-block-start-0 {
  margin-block-start: 0.3125rem;
}
.mg-block-end-0 {
  margin-block-end: 0.3125rem;
}

.pg-0 {
  padding: 0.3125rem;
}
.pg-inline-0 {
  padding-inline: 0.3125rem;
}
.pg-inline-start-0 {
  padding-inline-start: 0.3125rem;
}
.pg-inline-end-0 {
  padding-inline-end: 0.3125rem;
}
.pg-block-0 {
  padding-block: 0.3125rem;
}
.pg-block-start-0 {
  padding-block-start: 0.3125rem;
}
.pg-block-end-0 {
  padding-block-end: 0.3125rem;
}

.gap-0 {
  gap: 0.3125rem;
}

.mg-1 {
  margin: 0.625rem;
}
.mg-inline-1 {
  margin-inline: 0.625rem;
}
.mg-inline-start-1 {
  margin-inline-start: 0.625rem;
}
.mg-inline-end-1 {
  margin-inline-end: 0.625rem;
}
.mg-block-1 {
  margin-block: 0.625rem;
}
.mg-block-start-1 {
  margin-block-start: 0.625rem;
}
.mg-block-end-1 {
  margin-block-end: 0.625rem;
}

.pg-1 {
  padding: 0.625rem;
}
.pg-inline-1 {
  padding-inline: 0.625rem;
}
.pg-inline-start-1 {
  padding-inline-start: 0.625rem;
}
.pg-inline-end-1 {
  padding-inline-end: 0.625rem;
}
.pg-block-1 {
  padding-block: 0.625rem;
}
.pg-block-start-1 {
  padding-block-start: 0.625rem;
}
.pg-block-end-1 {
  padding-block-end: 0.625rem;
}

.gap-1 {
  gap: 0.625rem;
}

.mg-2 {
  margin: 0.9375rem;
}
.mg-inline-2 {
  margin-inline: 0.9375rem;
}
.mg-inline-start-2 {
  margin-inline-start: 0.9375rem;
}
.mg-inline-end-2 {
  margin-inline-end: 0.9375rem;
}
.mg-block-2 {
  margin-block: 0.9375rem;
}
.mg-block-start-2 {
  margin-block-start: 0.9375rem;
}
.mg-block-end-2 {
  margin-block-end: 0.9375rem;
}

.pg-2 {
  padding: 0.9375rem;
}
.pg-inline-2 {
  padding-inline: 0.9375rem;
}
.pg-inline-start-2 {
  padding-inline-start: 0.9375rem;
}
.pg-inline-end-2 {
  padding-inline-end: 0.9375rem;
}
.pg-block-2 {
  padding-block: 0.9375rem;
}
.pg-block-start-2 {
  padding-block-start: 0.9375rem;
}
.pg-block-end-2 {
  padding-block-end: 0.9375rem;
}

.gap-2 {
  gap: 0.9375rem;
}

.mg-3 {
  margin: 1.25rem;
}
.mg-inline-3 {
  margin-inline: 1.25rem;
}
.mg-inline-start-3 {
  margin-inline-start: 1.25rem;
}
.mg-inline-end-3 {
  margin-inline-end: 1.25rem;
}
.mg-block-3 {
  margin-block: 1.25rem;
}
.mg-block-start-3 {
  margin-block-start: 1.25rem;
}
.mg-block-end-3 {
  margin-block-end: 1.25rem;
}

.pg-3 {
  padding: 1.25rem;
}
.pg-inline-3 {
  padding-inline: 1.25rem;
}
.pg-inline-start-3 {
  padding-inline-start: 1.25rem;
}
.pg-inline-end-3 {
  padding-inline-end: 1.25rem;
}
.pg-block-3 {
  padding-block: 1.25rem;
}
.pg-block-start-3 {
  padding-block-start: 1.25rem;
}
.pg-block-end-3 {
  padding-block-end: 1.25rem;
}

.gap-3 {
  gap: 1.25rem;
}

.mg-4 {
  margin: 1.5625rem;
}
.mg-inline-4 {
  margin-inline: 1.5625rem;
}
.mg-inline-start-4 {
  margin-inline-start: 1.5625rem;
}
.mg-inline-end-4 {
  margin-inline-end: 1.5625rem;
}
.mg-block-4 {
  margin-block: 1.5625rem;
}
.mg-block-start-4 {
  margin-block-start: 1.5625rem;
}
.mg-block-end-4 {
  margin-block-end: 1.5625rem;
}

.pg-4 {
  padding: 1.5625rem;
}
.pg-inline-4 {
  padding-inline: 1.5625rem;
}
.pg-inline-start-4 {
  padding-inline-start: 1.5625rem;
}
.pg-inline-end-4 {
  padding-inline-end: 1.5625rem;
}
.pg-block-4 {
  padding-block: 1.5625rem;
}
.pg-block-start-4 {
  padding-block-start: 1.5625rem;
}
.pg-block-end-4 {
  padding-block-end: 1.5625rem;
}

.gap-4 {
  gap: 1.5625rem;
}

.mg-5 {
  margin: 1.875rem;
}
.mg-inline-5 {
  margin-inline: 1.875rem;
}
.mg-inline-start-5 {
  margin-inline-start: 1.875rem;
}
.mg-inline-end-5 {
  margin-inline-end: 1.875rem;
}
.mg-block-5 {
  margin-block: 1.875rem;
}
.mg-block-start-5 {
  margin-block-start: 1.875rem;
}
.mg-block-end-5 {
  margin-block-end: 1.875rem;
}

.pg-5 {
  padding: 1.875rem;
}
.pg-inline-5 {
  padding-inline: 1.875rem;
}
.pg-inline-start-5 {
  padding-inline-start: 1.875rem;
}
.pg-inline-end-5 {
  padding-inline-end: 1.875rem;
}
.pg-block-5 {
  padding-block: 1.875rem;
}
.pg-block-start-5 {
  padding-block-start: 1.875rem;
}
.pg-block-end-5 {
  padding-block-end: 1.875rem;
}

.gap-5 {
  gap: 1.875rem;
}

.mg-6 {
  margin: 2.1875rem;
}
.mg-inline-6 {
  margin-inline: 2.1875rem;
}
.mg-inline-start-6 {
  margin-inline-start: 2.1875rem;
}
.mg-inline-end-6 {
  margin-inline-end: 2.1875rem;
}
.mg-block-6 {
  margin-block: 2.1875rem;
}
.mg-block-start-6 {
  margin-block-start: 2.1875rem;
}
.mg-block-end-6 {
  margin-block-end: 2.1875rem;
}

.pg-6 {
  padding: 2.1875rem;
}
.pg-inline-6 {
  padding-inline: 2.1875rem;
}
.pg-inline-start-6 {
  padding-inline-start: 2.1875rem;
}
.pg-inline-end-6 {
  padding-inline-end: 2.1875rem;
}
.pg-block-6 {
  padding-block: 2.1875rem;
}
.pg-block-start-6 {
  padding-block-start: 2.1875rem;
}
.pg-block-end-6 {
  padding-block-end: 2.1875rem;
}

.gap-6 {
  gap: 2.1875rem;
}

.mg-7 {
  margin: 2.5rem;
}
.mg-inline-7 {
  margin-inline: 2.5rem;
}
.mg-inline-start-7 {
  margin-inline-start: 2.5rem;
}
.mg-inline-end-7 {
  margin-inline-end: 2.5rem;
}
.mg-block-7 {
  margin-block: 2.5rem;
}
.mg-block-start-7 {
  margin-block-start: 2.5rem;
}
.mg-block-end-7 {
  margin-block-end: 2.5rem;
}

.pg-7 {
  padding: 2.5rem;
}
.pg-inline-7 {
  padding-inline: 2.5rem;
}
.pg-inline-start-7 {
  padding-inline-start: 2.5rem;
}
.pg-inline-end-7 {
  padding-inline-end: 2.5rem;
}
.pg-block-7 {
  padding-block: 2.5rem;
}
.pg-block-start-7 {
  padding-block-start: 2.5rem;
}
.pg-block-end-7 {
  padding-block-end: 2.5rem;
}

.gap-7 {
  gap: 2.5rem;
}

.mg-8 {
  margin: 2.8125rem;
}
.mg-inline-8 {
  margin-inline: 2.8125rem;
}
.mg-inline-start-8 {
  margin-inline-start: 2.8125rem;
}
.mg-inline-end-8 {
  margin-inline-end: 2.8125rem;
}
.mg-block-8 {
  margin-block: 2.8125rem;
}
.mg-block-start-8 {
  margin-block-start: 2.8125rem;
}
.mg-block-end-8 {
  margin-block-end: 2.8125rem;
}

.pg-8 {
  padding: 2.8125rem;
}
.pg-inline-8 {
  padding-inline: 2.8125rem;
}
.pg-inline-start-8 {
  padding-inline-start: 2.8125rem;
}
.pg-inline-end-8 {
  padding-inline-end: 2.8125rem;
}
.pg-block-8 {
  padding-block: 2.8125rem;
}
.pg-block-start-8 {
  padding-block-start: 2.8125rem;
}
.pg-block-end-8 {
  padding-block-end: 2.8125rem;
}

.gap-8 {
  gap: 2.8125rem;
}

.mg-9 {
  margin: 3.125rem;
}
.mg-inline-9 {
  margin-inline: 3.125rem;
}
.mg-inline-start-9 {
  margin-inline-start: 3.125rem;
}
.mg-inline-end-9 {
  margin-inline-end: 3.125rem;
}
.mg-block-9 {
  margin-block: 3.125rem;
}
.mg-block-start-9 {
  margin-block-start: 3.125rem;
}
.mg-block-end-9 {
  margin-block-end: 3.125rem;
}

.pg-9 {
  padding: 3.125rem;
}
.pg-inline-9 {
  padding-inline: 3.125rem;
}
.pg-inline-start-9 {
  padding-inline-start: 3.125rem;
}
.pg-inline-end-9 {
  padding-inline-end: 3.125rem;
}
.pg-block-9 {
  padding-block: 3.125rem;
}
.pg-block-start-9 {
  padding-block-start: 3.125rem;
}
.pg-block-end-9 {
  padding-block-end: 3.125rem;
}

.gap-9 {
  gap: 3.125rem;
}

.mg-10 {
  margin: 3.4375rem;
}
.mg-inline-10 {
  margin-inline: 3.4375rem;
}
.mg-inline-start-10 {
  margin-inline-start: 3.4375rem;
}
.mg-inline-end-10 {
  margin-inline-end: 3.4375rem;
}
.mg-block-10 {
  margin-block: 3.4375rem;
}
.mg-block-start-10 {
  margin-block-start: 3.4375rem;
}
.mg-block-end-10 {
  margin-block-end: 3.4375rem;
}

.pg-10 {
  padding: 3.4375rem;
}
.pg-inline-10 {
  padding-inline: 3.4375rem;
}
.pg-inline-start-10 {
  padding-inline-start: 3.4375rem;
}
.pg-inline-end-10 {
  padding-inline-end: 3.4375rem;
}
.pg-block-10 {
  padding-block: 3.4375rem;
}
.pg-block-start-10 {
  padding-block-start: 3.4375rem;
}
.pg-block-end-10 {
  padding-block-end: 3.4375rem;
}

.gap-10 {
  gap: 3.4375rem;
}

.mg-11 {
  margin: 4.375rem;
}
.mg-inline-11 {
  margin-inline: 4.375rem;
}
.mg-inline-start-11 {
  margin-inline-start: 4.375rem;
}
.mg-inline-end-11 {
  margin-inline-end: 4.375rem;
}
.mg-block-11 {
  margin-block: 4.375rem;
}
.mg-block-start-11 {
  margin-block-start: 4.375rem;
}
.mg-block-end-11 {
  margin-block-end: 4.375rem;
}

.pg-11 {
  padding: 4.375rem;
}
.pg-inline-11 {
  padding-inline: 4.375rem;
}
.pg-inline-start-11 {
  padding-inline-start: 4.375rem;
}
.pg-inline-end-11 {
  padding-inline-end: 4.375rem;
}
.pg-block-11 {
  padding-block: 4.375rem;
}
.pg-block-start-11 {
  padding-block-start: 4.375rem;
}
.pg-block-end-11 {
  padding-block-end: 4.375rem;
}

.gap-11 {
  gap: 4.375rem;
}

.mg-12 {
  margin: 6.25rem;
}
.mg-inline-12 {
  margin-inline: 6.25rem;
}
.mg-inline-start-12 {
  margin-inline-start: 6.25rem;
}
.mg-inline-end-12 {
  margin-inline-end: 6.25rem;
}
.mg-block-12 {
  margin-block: 6.25rem;
}
.mg-block-start-12 {
  margin-block-start: 6.25rem;
}
.mg-block-end-12 {
  margin-block-end: 6.25rem;
}

.pg-12 {
  padding: 6.25rem;
}
.pg-inline-12 {
  padding-inline: 6.25rem;
}
.pg-inline-start-12 {
  padding-inline-start: 6.25rem;
}
.pg-inline-end-12 {
  padding-inline-end: 6.25rem;
}
.pg-block-12 {
  padding-block: 6.25rem;
}
.pg-block-start-12 {
  padding-block-start: 6.25rem;
}
.pg-block-end-12 {
  padding-block-end: 6.25rem;
}

.gap-12 {
  gap: 6.25rem;
}

.mg-13 {
  margin: 7.5rem;
}
.mg-inline-13 {
  margin-inline: 7.5rem;
}
.mg-inline-start-13 {
  margin-inline-start: 7.5rem;
}
.mg-inline-end-13 {
  margin-inline-end: 7.5rem;
}
.mg-block-13 {
  margin-block: 7.5rem;
}
.mg-block-start-13 {
  margin-block-start: 7.5rem;
}
.mg-block-end-13 {
  margin-block-end: 7.5rem;
}

.pg-13 {
  padding: 7.5rem;
}
.pg-inline-13 {
  padding-inline: 7.5rem;
}
.pg-inline-start-13 {
  padding-inline-start: 7.5rem;
}
.pg-inline-end-13 {
  padding-inline-end: 7.5rem;
}
.pg-block-13 {
  padding-block: 7.5rem;
}
.pg-block-start-13 {
  padding-block-start: 7.5rem;
}
.pg-block-end-13 {
  padding-block-end: 7.5rem;
}

.gap-13 {
  gap: 7.5rem;
}

.mg-14 {
  margin: 9.375rem;
}
.mg-inline-14 {
  margin-inline: 9.375rem;
}
.mg-inline-start-14 {
  margin-inline-start: 9.375rem;
}
.mg-inline-end-14 {
  margin-inline-end: 9.375rem;
}
.mg-block-14 {
  margin-block: 9.375rem;
}
.mg-block-start-14 {
  margin-block-start: 9.375rem;
}
.mg-block-end-14 {
  margin-block-end: 9.375rem;
}

.pg-14 {
  padding: 9.375rem;
}
.pg-inline-14 {
  padding-inline: 9.375rem;
}
.pg-inline-start-14 {
  padding-inline-start: 9.375rem;
}
.pg-inline-end-14 {
  padding-inline-end: 9.375rem;
}
.pg-block-14 {
  padding-block: 9.375rem;
}
.pg-block-start-14 {
  padding-block-start: 9.375rem;
}
.pg-block-end-14 {
  padding-block-end: 9.375rem;
}

.gap-14 {
  gap: 9.375rem;
}

.mg-15 {
  margin: 10.625rem;
}
.mg-inline-15 {
  margin-inline: 10.625rem;
}
.mg-inline-start-15 {
  margin-inline-start: 10.625rem;
}
.mg-inline-end-15 {
  margin-inline-end: 10.625rem;
}
.mg-block-15 {
  margin-block: 10.625rem;
}
.mg-block-start-15 {
  margin-block-start: 10.625rem;
}
.mg-block-end-15 {
  margin-block-end: 10.625rem;
}

.pg-15 {
  padding: 10.625rem;
}
.pg-inline-15 {
  padding-inline: 10.625rem;
}
.pg-inline-start-15 {
  padding-inline-start: 10.625rem;
}
.pg-inline-end-15 {
  padding-inline-end: 10.625rem;
}
.pg-block-15 {
  padding-block: 10.625rem;
}
.pg-block-start-15 {
  padding-block-start: 10.625rem;
}
.pg-block-end-15 {
  padding-block-end: 10.625rem;
}

.gap-15 {
  gap: 10.625rem;
}/*# sourceMappingURL=Main.css.map */
//logo
$logo-size: $size-7;
$logo-text: $fs-500;

//headers
$header-link-color: $clr-dark-400;
$header-link-color-2: $clr-accent-400;
$header-font-weight: $fw-medium;
$header-height: 6.25rem;

//body
$body-line-height: 42px;

//heading
$secondary-heading-padding: $size-13;

//footer
$footer-block-padding: $size-10;
$footer-background-color: $clr-dark-400;
$footer-color: $clr-primary-800;


//section
$section-padding: $size-13;

//divider
$divider-color: hsla(0, 0%, 0%, 0.315);
$divider-height: 1px;
$divider-margin-block: 0px;

$border-circle: 50vw;

//inputs
$input-background-color: transparent;
$input-color: $clr-primary-400; 
$input-padding-block: $size-1;
$input-padding-inline: $size-1;
$input-border: 1.5px solid hsl(from $clr-primary-400 h s l / .5);
$input-border-radius: 2px;